<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-12">
          <h2 class="font-weight-bolder">ประวัติยอดเงิน</h2>
          <hr />
          <b-tabs content-class="mt-2" v-model="tab" lazy fill pills>
            <b-tab title="เติมเงิน"><list-deposit /></b-tab>
            <b-tab title="ถอนเงิน"><list-withdraw @changeTab="changeTab" /></b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import listDeposit from './components/list-deposit.vue'
import listWithdraw from './components/list-withdraw.vue'

export default {
  components: {
    BTabs,
    BTab,
    listDeposit,
    listWithdraw
  },
  data() {
    return {
      tab: 0,
    }
  },
  watch: {
    tab(e) {
      console.log(e)
      if (e === 0) {
       this.$router.push('/history/deposit')
      } else {
       this.$router.push('/history/withdraw')
      }
    }
  },
  beforeMount() {
    const type = this.$route.params.type
    if (type === 'withdraw') {
      this.tab = 1
    } else if (type === 'deposit') {
      this.tab = 0
    } else {
      this.$route.push('/')
    }
  },
  created() {
    const type = this.$route.params.type
    if (type === 'withdraw') {
      this.tab = 1
    } else if (type === 'deposit') {
      this.tab = 0
    } else {
      this.$route.push('/')
    }
  },
  methods: {
    changeTab(val) {
      this.tab = val
    },
  }
};
</script>

<style>
</style>
