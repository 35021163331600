<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <!-- <h3>รายการถอนเงิน</h3> -->
        </div>
        <div class="row">
          <div class="col-12 col-sm-4 col-xl-3">
            <b-form-group label="เริ่มต้น" label-for="vi-first-name">
              <DatePicker
                :date="dateStart"
                :type="'start'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-4 col-xl-3">
            <b-form-group
              label="
            สิ้นสุด"
              label-for="vi-first-name"
            >
              <DatePicker
                :date="dateEnd"
                :type="'end'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-4 align-self-center mb-1">
            <b-form-checkbox
              v-model="checkedList"
              checked="true"
              name="check-button"
              switch
              inline
            >
              ทั้งหมด
            </b-form-checkbox>
          </div>
        </div>
        <!-- <div class="row justify-content-end">
          <div class="col-12 col-sm-4 col-xl-3">
            <b-form-select
              v-model="status"
              class="mb-1"
              :options="opption"
            />
          </div>
        </div> -->
        <b-table
          striped
          hover
          responsive
          class="type-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="listWithdraw"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >
          <template #cell(fulltime)="data">
            {{ data.item.created_at | toTimeThai }}
          </template>
          <template #cell(detail)="data">
            <b-badge
              v-if="data.item.detail.at(-1) === 'รอดำเนินการ'"
              variant="secondary"
              >{{ data.item.detail.at(-1) }}</b-badge
            >
            <b-badge
              v-if="data.item.detail.at(-1) === 'เกิดข้อผิดพลาด'"
              variant="danger"
              >{{ data.item.detail.at(-1) }}</b-badge
            >
            <b-badge
              v-if="data.item.detail.at(-1) === 'ยกเลิกรายการ'"
              variant="danger"
              >{{ data.item.detail.at(-1) }}</b-badge
            >
            <b-badge
              v-if="data.item.detail.at(-1) === 'ดำเนินการเรียบร้อย'"
              variant="success"
              >{{ data.item.detail.at(-1) }}</b-badge
            >
          </template>
          <template #cell(creditAmount)="data">
            {{ data.item.creditAmount | toCurrencyBath }}
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการถอนเงิน</h4>
            </div>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0 mt-2">
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCardBody,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BPagination,
  BTable,
  BBadge,
} from "bootstrap-vue";
import DatePicker from "../../components/datePicker.vue";
import moment from "moment";
// import { defineComponent } from '@vue/composition-api'

export default {
  components: {
    DatePicker,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    BPagination,
    BTable,
    BBadge,
  },
  data() {
    return {
      id: '',
      listWithdraw: [],
      dateStart: moment().subtract(7, 'days').format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      inputsnode: {
        perPage: 5,
        pageOptions: [5, 10, 15],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10,
      },
      fields: [
        {
          key: "fulltime",
          label: "วันที่/เวลา",
          thStyle: "min-width: 165px",
          sortable: true,
        },
        {
          key: "creditAmount",
          label: "จำนวนเงิน",
          thStyle: "min-width: 165px",
          sortable: false,
        },
        {
          key: "detail",
          label: "รายละเอียด",
          thStyle: "min-width: 165px",
          sortable: false,
        },
      ],
      checkedList: false,
      searchTerm: "",
    };
  },
  watch: {
    dateStart() {
      this.getListWithdraw();
    },
    dateEnd() {
      this.getListWithdraw();
    },
    checkedList() {
      this.getListWithdraw();
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.id = userData.id;
    this.getListWithdraw();
  },
  methods: {
    onSetDatePicker(data) {
      if (data.type === "start")
        this.dateStart = moment(data.val).format("YYYY-MM-DD");
      else this.dateEnd = moment(data.val).format("YYYY-MM-DD");
    },
    onFiltered(filteredItems) {
      this.inputsnode.totalRows = filteredItems.length;
      this.inputsnode.currentPage = 1;
    },
    getListWithdraw() {
      this.$store
        .dispatch("transaction/getWithdraw", {
          startDate: this.checkedList ? "" : this.dateStart,
          endDate: this.checkedList ? "" : this.dateEnd,
          id: this.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.listWithdraw = res.data.items;
            this.inputsnode.totalRows = this.listWithdraw.length;
          }
          console.log(res);
        });
    },
  },
};
</script>

<style></style>
