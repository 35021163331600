<template>

  <div>
    <datepicker
      v-model="datePicker"
      class="mb-1"
      style="width:100%"
      format="DD/MM/YYYY"
      lang="th"
    />
  </div>

</template>
<script>
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { defineComponent } from '@vue/composition-api'
import { BFormDatepicker } from 'bootstrap-vue'

export default defineComponent({
  name: 'date_picker',
  components: {
    BFormDatepicker,
    Datepicker
  },
  props: {
    date: String,
    status: Boolean,
    type: String
  },
  data () {
    return {
      datePicker: new Date(this.date)
    }
  },
  watch: {
    datePicker (event) {
      const datas = {
        type: this.type,
        val: event
      }
      this.$emit('setDatePicker', datas)
    }
  },
  methods: {
    setdate (val) {
      console.log('))))', val)
      this.datePicker = new Date(val)
    }
  },
  computed: {
    statusReadOnly () {
      return this.status === '' ? false : this.status
    }
  }

})
</script>
